import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NavigationBar.css';
import logoName from '../../assets/logo rosewood.PNG';

const NavigationBar = () => {

  return (
    <nav className="navbar">
      <div className="logo-container">
        <div className="logo-item">
          <Link to="/">
            <img 
              src={logoName}
              alt="Logo"
              className="logo-name"
            />
          </Link>
        </div>
      </div>
      <div className="navlink-container">
        <div className="nav justify-content-end">
          <li className="nav-item">
            <Link className="nav-link" to="/">About Us</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/services">Services</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact">Contact</Link>
          </li>
          {/* <Link className="cta1" to="/contact">Get Started</Link> */}
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
