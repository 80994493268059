import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logoImage from '../../assets/Logo2.PNG';
import './Overview.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavigationBar from '../Navbar/NavigationBar';
import { faCheck, faChartLine, faChessPawn, faMagnifyingGlassDollar, faArrowRight, faArrowDown, faUndo, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import '../../assets/styles/generalStyle.css';
// import MultiLevelCarousel from '../../elements/MultiLevelCarousel.js';


const Overview = () => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const words = ['Accounting.', 'Finance.', 'Strategy', 'Marketing.', 'Product.', 'Fundraising.'];
    const words2 = ['Honesty.', 'Respect.', 'Humility.', 'Kindness.', 'Trust.', 'Camaraderie.']
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      }, 4000);
  
      return () => clearInterval(interval);
    }, [words.length]);

   
    const [expandedCardIndex, setExpandedCardIndex] = useState(-1);

    const toggleCardExpansion = (index) => {
        if (expandedCardIndex === index) {
            setExpandedCardIndex(-1);
        } else {
            setExpandedCardIndex(index);
        }
    };
  
    return (
        <div className="page-wrapper">
            <NavigationBar/>

            <div className="section-padding">
                <div className="center-content-general">
                    <div className="logo-image-box">
                        <img
                            className="logo-image"
                            alt="Logo"
                            src={logoImage}
                            />
                    </div>
                    <div className="main-header">
                        <div className="rosewood-header">
                            <h1 className="gradient-text-large">Rosewood Advisors</h1>
                            <AnimatedText className="gradient-text-large">
                                {words[currentWordIndex]}
                            </AnimatedText>
                        </div>
                    </div>

                    <hr className="small-separator"></hr>
                    
                    <div className="footer-header">
                        <p className="white-text-small-medium">
                        Accounting, Finance, Data and Advisory for Growing Companies.<br/>   
                        </p>                
                        <hr className="small-separator"></hr>
                        <p className="white-text-small">
                        Providing exeutive teams with data and efficiency since 2008
                        </p>
                    </div>
                    
                    <hr className="medium-separator"></hr>
                    
                    <Link to="/contact" className="cta1" style={{ textDecoration: 'none' }}>
                        Contact Us <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                </div>
            </div>

            <div className="section-padding">
                <div className="main-header">
                    <p className="gradient-text-medium-large">About Rosewood</p>
                </div>
                <hr className="small-separator"></hr>
                <div className="center-container">
                    <h1 className='white-text-small'>We are experts at the boring things - so you don't have to be.</h1>
                </div>
                <hr className="small-separator"></hr>
                <div className="content-container">
                    <div className="about-text-container">
                        <hr className="small-separator"></hr>
                        <p className="small-text">Accounting, tax, finance, operations, and data analytics are fundamental for every business, but they're not what founders and CEOs need to be focused on. Let Rosewood handle the back office for your company, so you can focus on the big picture.</p>
                    </div>
                    <div className="about-check-container">
                        <ul className="custom-list">
                            <li>
                                {/* <FontAwesomeIcon icon={faCheck} className="icon-color" /> */}
                                <span className="white-text-small">We provide:</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} className="icon-color" />
                                <span className="small-text">A team of executive-level domain experts</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} className="icon-color" />
                                <span className="small-text">Planning, implementation, and maintenance of core services</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheck} className="icon-color" />
                                <span className="small-text">Data-driven insights across your entire company</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr className="small-separator"></hr>
                <div className="cta-bar">
                    <Link to="/contact" className="cta1" style={{ textDecoration: 'none' }}>
                        Let's Get Started <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                </div>
            </div>

         
                   

            <div className="section-padding">
                <div className="gradient-container">
                    <p className="gradient-text-medium-large">Our Values.</p>
                </div>
                <div className="content-container">
                    <div className="text-container">
                        <AnimatedText className="gradient-text-medium">
                            {words2[currentWordIndex]}
                        </AnimatedText>
                        <div className="mvv-text-container">
                            <div className="white-text-small-medium">
                                We pride ourselves on being thoughtful, intelligent, and kind communicators.
                                We believe that trust is earned, and that respect is the foundation of any good relationship.
                                We put relationships above dollar signs, and we surround ourselves with people who share these beliefs.
                                
                                </div>        
                        </div>

                    </div>

                    <hr className="small-separator"></hr>
                    <div className="mvv-expandable-text-container">
                        {[
                            { title: 'Our Mission.', additionalText: 
                                'Our mission is to enable founders, entrepreneurs, and CEOs to focus on their passion for their startup or small businesses. We handle the rest.' 
                            },

                            { title: 'Our Vision.', additionalText: 'We believe in a world where founders and CEOs no longer spend significant time doing bookkeeping, budgeting, financial reporting, and analytics. These are cruical inputs to the decision-making process, and they often come at the expense of CEO time until a team can be hired. Companies are more receptive than ever to oursourcing these services, and value will accrue to comapnies who can effectively package these functions into a cohesive offering. ' 

                            },
                            { title: 'Our Values.', additionalText: "We believe in treating others with honesty, respect, patience, and integreity. We work with your team to build camaraderie and we strive to always be humble and kind. We bring these values to work with us every day, and we approach every engagement as a chance to build mutual trust and respect with our clients." 
                            
                            }
                        ].map((cardData, index) => (
                            <div
                            key={index}
                            className={`gradient-text-small card-belief ${expandedCardIndex === index ? 'expanded' : ''}`}
                            onClick={() => toggleCardExpansion(index)}
                            >
                            <div className="card-title-container">
                                {cardData.title}
                                <FontAwesomeIcon icon={faArrowDown} className="icon-color" />
                            </div>    
                            {expandedCardIndex === index && (
                                <div className="small-text expanded-content">
                                    {cardData.additionalText}
                                </div>
                            )}
                            </div>
                        ))}
                    </div>
                </div>
                <hr className="medium-separator"></hr>
                <div className="cta-bar">
                    <Link to="/contact" className="cta1" style={{ textDecoration: 'none' }}>
                        Start Today <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                </div>
            </div>


            <div className="about-us-2-container section-padding">
                
                <div className="header-container">
                    <h1 className='white-text-medium-large'>Partner with Rosewood</h1>
                    <h1 className='gradient-text-medium'>We've got you covered.</h1>     
                </div>
                
                <hr className="medium-separator"></hr>

                <div className="content-container">
                    <div className="text-container-b">
                        <div className="content-container-b">
                            <FontAwesomeIcon icon={faPlayCircle} className="card-icon" />
                            <div className="text-box-b">
                                <span className="white-text-medium">We know what it takes to succeed</span>
                                <p className="small-text">Our team brings diverse backgrounds and experiences to approach problems from all angles. We each bring unique perspectives, and to communicate effectively with any stakeholder. We are an innovative consultancy designed for innovative companies.</p>
                            </div>
                        </div>
                        <hr className="small-separator"></hr>
                        <div className="content-container-b">
                            <FontAwesomeIcon icon={faUndo} className="card-icon" />
                            <div className="text-box-b">
                                <span className="white-text-medium">We're in the weeds with you</span>
                                <p className="small-text">It takes conviction, fortitude, and the right mix of team players to run a successful company. We’re there with you every step of the way.</p>
                            </div>
                        </div>                        
                    </div>
                </div>
              </div>

            <div className= "send-message section-padding">
                <div className="gradient-container">
                    <p className="white-text-medium-large">Send us a message.</p>
                </div>
                <div className="cta-bar">
                    <Link to="/contact" className="cta1" style={{ textDecoration: 'none' }}>
                        Contact Us <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                </div>
            </div>
            <NavigationBar/>
        </div>
    );
  };
  
  export default Overview;
  
  // Estilos de los componentes animados

// Estilos de los componentes animados
const AnimatedText = styled.h1`
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  animation: typingAnimation 4s 0s infinite;

  @keyframes typingAnimation {
    0% {
      width: 0%;
    }
    50% {
      width: 100%;
    }
    100% {
        width: 0%;
      }
  }

  
  font-family: 'Sofia Pro', sans-serif;
  background: linear-gradient(90deg, rgba(237,197,113,1) 0%, rgba(213,95,35,1) 50%, rgba(237,197,113,1) 100%);
  -webkit-background-clip: text;
  color: transparent;
  margin: 0;
  font-weight: bold;
`;